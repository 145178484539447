import { ref, defineComponent, onMounted, onUnmounted, computed } from '@vue/composition-api';
import useImageResize from '@/shared/composables/useImageResize';
import { applyCMSImageFilters } from '@/StoryBlok/helpers';
export default defineComponent({
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(props, __) {
        const img = ref(null);
        const { imageHeight, imageWidth, onScreenResize } = useImageResize(img, {
            defaultHeight: props.blok.height || 500,
            defaultWidth: props.blok.width || 600,
            beginResizingAt: 1220,
            widthModificator: 70,
            resizeHeight: true
        });
        const imgSrc = computed(() => {
            if (!props.blok.heroImage || !props.blok.heroImage.filename) {
                return '';
            }
            // https://www.storyblok.com/docs/Guides/how-to-resize-images
            return applyCMSImageFilters(props.blok.heroImage.filename, imageWidth.value);
        });
        onMounted(() => {
            window.addEventListener('resize', onScreenResize);
            onScreenResize();
        });
        onUnmounted(() => {
            window.removeEventListener('resize', onScreenResize);
        });
        return {
            imageHeight,
            imageWidth,
            img,
            imgSrc
        };
    }
});
