import Vue from 'vue';
import { dateFormatter } from '@/util/dates';
import VenueDetailsModel from '@/shared/models/venue/VenueDetailsModel';
class VenueDetailsForm extends VenueDetailsModel {
    _workingTimeConflicts(vue, form, errorCode) {
        Object.keys(form.workingTime).forEach(dayName => {
            if (form.workingTime[dayName].length) {
                const currentDayOpeningTime = form.workingTime[dayName][0].open;
                const currentDayClosingTime = form.workingTime[dayName][0].close;
                const currentDayOpeningTimeParsed = dateFormatter(`${dayName} ${currentDayOpeningTime}`, 'ddd H:m');
                const currentDayClosingTimeParsed = dateFormatter(`${dayName} ${currentDayClosingTime}`, 'ddd H:m');
                if (currentDayClosingTimeParsed <= currentDayOpeningTimeParsed) {
                    currentDayClosingTimeParsed.add(1, 'd');
                }
                currentDayOpeningTimeParsed.add(1, 'd');
                const nextDaySignature = currentDayOpeningTimeParsed
                    .format('ddd')
                    .toUpperCase();
                const nextDay = form.workingTime[nextDaySignature];
                if (nextDay && nextDay.length) {
                    const nextDayOpeningTime = nextDay[0].open;
                    const nextDayOpeningTimeParsed = dateFormatter(`${nextDaySignature} ${nextDayOpeningTime}`, 'ddd H:m');
                    if (dayName === 'SUN')
                        nextDayOpeningTimeParsed.add(1, 'w');
                    if (currentDayClosingTimeParsed > nextDayOpeningTimeParsed) {
                        Vue.set(form._errors, 'workingTime', globalThis.$i18n.t(errorCode));
                    }
                }
            }
        });
    }
    _workingTimeRequired(vue, form, errorCode) {
        let workingDayExists = false;
        Object.keys(form.workingTime).forEach((dayName) => {
            if (form.workingTime[dayName].length) {
                workingDayExists = true;
            }
        });
        if (!workingDayExists) {
            Vue.set(form._errors, 'workingTime', globalThis.$i18n.t(errorCode));
        }
    }
    _validAddressFound(vue, form, errorCode) {
        if (!form._isAddressFound) {
            Vue.set(form._errors, 'address', globalThis.$i18n.t(errorCode));
        }
    }
    _contactPhoneRequired(vue, form, errorCode) {
        const phone = form.contactPhone;
        if (typeof phone === 'string' && phone !== '') {
            return;
        }
        if (typeof phone === 'object' && phone !== {} && phone.formatted_number) {
            return;
        }
        Vue.set(form._errors, 'contactPhone', globalThis.$i18n.t(errorCode));
    }
    loadFromModel(model, except) {
        super.loadFromModel(model, except);
        if (this.address) {
            this._isAddressFound = true;
        }
    }
    setupAddress(placeID, address) {
        this.placeID = placeID;
        this.address = address;
        this._isAddressFound = true;
        Vue.set(this._errors, 'address', '');
    }
    dropAddress() {
        this._isAddressFound = false;
        Vue.set(this._errors, 'address', '');
    }
    failAddress(vueInstance) {
        this._isAddressFound = false;
        Vue.set(this._errors, 'address', vueInstance.$t('onboarding.venue.details.errors.address_not_found'));
    }
    hideAttributes() {
        return ['id', 'features', 'additionalAddress', 'address', 'slug'];
    }
    requiredFields() {
        return [
            'name',
            'description',
            'address',
            'contactName',
            'contactPhone',
            'contactEmail',
            'images',
            'workingTime'
        ];
    }
    validationRules() {
        return {
            name: [
                {
                    validation: 'required',
                    i18code: 'onboarding.venue.details.errors.name_empty'
                },
                {
                    validation: 'maxLength',
                    value: 100,
                    i18code: 'onboarding.venue.details.errors.name_too_long'
                },
                {
                    validation: 'minLength',
                    value: 3,
                    i18code: 'onboarding.venue.details.errors.name_too_short'
                }
            ],
            description: [
                {
                    validation: 'maxLength',
                    value: 3000,
                    i18code: 'onboarding.venue.details.errors.description_too_long'
                },
                {
                    validation: 'minLength',
                    value: 3,
                    i18code: 'onboarding.venue.details.errors.description_too_short'
                }
            ],
            venueRules: [
                {
                    validation: 'maxLength',
                    value: 3000,
                    i18code: 'onboarding.venue.details.errors.description_too_long'
                }
            ],
            placeID: [
                {
                    validation: 'required',
                    i18code: 'onboarding.venue.details.errors.address_not_selected'
                }
            ],
            address: [
                {
                    validation: 'required',
                    i18code: 'onboarding.venue.details.errors.address_empty'
                },
                {
                    validation: this._validAddressFound,
                    i18code: 'onboarding.venue.details.errors.address_not_found'
                }
            ],
            contactName: [
                {
                    validation: 'required',
                    i18code: 'onboarding.venue.details.errors.contact_name_empty'
                },
                {
                    validation: 'minLength',
                    value: 3,
                    i18code: 'onboarding.venue.details.errors.contact_name_too_short'
                },
                {
                    validation: 'maxLength',
                    value: 80,
                    i18code: 'onboarding.venue.details.errors.contact_name_too_long'
                }
            ],
            contactPhone: [
                {
                    validation: this._contactPhoneRequired,
                    i18code: 'onboarding.venue.details.errors.contact_phone_empty'
                }
            ],
            contactEmail: [
                {
                    validation: 'required',
                    i18code: 'onboarding.venue.details.errors.contact_email_empty'
                },
                {
                    validation: 'email',
                    value: 100,
                    i18code: 'onboarding.venue.details.errors.contact_email_incorrect'
                }
            ],
            workingTime: [
                {
                    validation: this._workingTimeRequired,
                    i18code: 'onboarding.venue.details.errors.working_time_empty'
                }
            ],
            photos: [
                {
                    validation: this._imagesRequired,
                    i18code: 'onboarding.venue.details.errors.images_empty'
                }
            ]
        };
    }
}
export default VenueDetailsForm;
