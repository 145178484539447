import SearchBar from '@/Search/components/SearchBar.vue';
import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api';
import HighlightedLocations from '@/Home/HighlightedLocations.vue';
import Testimonials from '@/Home/Testimonials.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import WhatWeDoBlock from '@/Home/WhatWeDoBlock.vue';
import useImageResize from '@/shared/composables/useImageResize';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
import HomeInfo from '@/Home/HomeInfo.vue';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { SITE_URL } from '@/App.vue';
import { socialSharingOptions, homeInfoBlocks } from '@/Home/home.const';
import { applyCMSImageFilters } from '@/StoryBlok/helpers';
export default defineComponent({
    components: {
        HomeInfo,
        HighlightedLocations,
        SearchBar,
        Testimonials,
        WhatWeDoBlock
    },
    props: {
        blok: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root } = context;
        const url = ref(`${SITE_URL}${root.$router.currentRoute.path}`);
        const img = ref(null);
        const { imageWidth, onScreenResize } = useImageResize(img, {
            defaultWidth: 1280,
            beginResizingAt: 1320,
            widthModificator: 40
        });
        const imgSrc = computed(() => {
            if (!props.blok.hero_image || !props.blok.hero_image.filename) {
                return '';
            }
            // https://www.storyblok.com/docs/Guides/how-to-resize-images
            return applyCMSImageFilters(props.blok.hero_image.filename, imageWidth.value);
        });
        const copyLink = () => {
            // Adding toaster and copying to clipboard
            const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
            root.$copyText(SITE_URL);
            addToast({
                text: root.$i18n.t('common.social_sharing.link_copied'),
                type: 'success',
                dismissAfter: 4000
            });
        };
        const goToLocations = () => globalThis.$router.push(useGetLocalizedPath(GeoPagesRoutes.CITIES));
        onMounted(() => {
            window.addEventListener('resize', onScreenResize);
            onScreenResize();
        });
        onUnmounted(() => {
            window.removeEventListener('resize', onScreenResize);
        });
        return {
            homeInfoBlocks,
            imageWidth,
            img,
            imgSrc,
            socialSharingOptions,
            url,
            copyLink,
            goToLocations
        };
    }
});
