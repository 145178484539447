import { defineComponent, watch } from '@vue/composition-api';
import vClickOutside from 'v-click-outside';
export default defineComponent({
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        value: {
            type: [Object, Boolean, Array, Number, String],
            default: null
        },
        hasCloseButton: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const { emit } = context;
        const onClose = () => {
            emit('close');
            emit('input', false);
        };
        watch(() => props.value, () => {
            if (!props.value) {
                document.body.style.overflow = 'auto';
            }
            else {
                document.body.style.overflow = 'hidden';
            }
        }, { immediate: true });
        const outsideEvent = () => {
            if (props.hasCloseButton) {
                onClose();
            }
        };
        return {
            onClose,
            outsideEvent
        };
    }
});
